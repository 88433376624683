<template lang="pug">
.account-form

  .form-line
    TextInput(
      label="Nome",
      v-model.trim="state.firstName",
      :mask="standardMaxLengthMask",
      @blur="v$.firstName.$touch",
      :errors="v$.firstName.$errors",
      autocomplete="given-name")
    TextInput(
      label="Sobrenome",
      v-model.trim="state.lastName",
      :mask="standardMaxLengthMask",
      @blur="v$.lastName.$touch",
      :errors="v$.lastName.$errors",
      autocomplete="family-name")

  .form-line
    TextInput(
      label="CPF",
      v-model="state.cpf",
      :mask="maskCPF",
      @blur="v$.cpf.$touch",
      :errors="v$.cpf.$errors",
      style="flex-grow: 4; min-width: 240px")
    TextInput(
      label="Data de nasc.",
      v-model="state.birthdate",
      :mask="maskDate",
      @blur="v$.birthdate.$touch",
      :errors="v$.birthdate.$errors",
      style="flex-grow: 1",
      autocomplete="bday")

  .form-line
    TextInput(
      label="Telefone",
      type="phoneNumber",
      v-model.trim="state.phoneNumber",
      :mask="maskPhoneNumber",
      @blur="v$.phoneNumber.$touch",
      :errors="v$.phoneNumber.$errors",
      autocomplete="tel")

  .form-line
    TextInput(
      label="E-mail",
      type="email",
      v-model.trim="state.email",
      @blur="v$.email.$touch",
      :errors="v$.email.$errors",
      autocomplete="email")

  .form-line
    TextInput(
      label="Senha",
      type="password",
      v-model="state.password",
      @blur="v$.password.$touch",
      :errors="v$.password.$errors",
      autocomplete="new-password")
    TextInput(
      label="Confirmação de senha",
      type="password",
      v-model="state.passwordConfirmation",
      @blur="v$.passwordConfirmation.$touch",
      :errors="v$.passwordConfirmation.$errors",
      autocomplete="new-password")

  .form-line
    CheckboxInput(
      label="Li e concordo com nossa política de privacidade e termos de uso",
      v-model="state.acceptsTermsOfUseAndPrivacyPolicy",
      @blur="v$.acceptsTermsOfUseAndPrivacyPolicy.$touch",
      :errors="v$.acceptsTermsOfUseAndPrivacyPolicy.$errors",
    )
      template(v-slot:label)
        | Li e concordo com nossa&#32;
        NuxtLink(:to="{ name: 'privacy-policy' }", target="_blank")
          | Política&nbsp;de&nbsp;Privacidade
        | &#32;e&#32;
        NuxtLink(:to="{ name: 'terms-of-use' }", target="_blank")
          | Termos&nbsp;de&nbsp;Uso
        |.

  .form-line
    CheckboxInput(
      label="Quero receber e-mails com novidades e promoções",
      v-model="state.acceptsPromotionalEmails",
    )
</template>
<script setup lang="ts">
import type { PropType } from 'vue'

import useVuelidate from '@vuelidate/core'

import { maskDate, maskCPF, maskPhoneNumber, maxLengthMask } from '~/scripts/masks/masks'
import { required, email, date, validCPF, validPhoneNumber, sameAs, minLength } from '~/scripts/validators/validators'

export interface AccountFormModel {
  firstName: string,
  lastName: string,
  birthdate: string,
  cpf: string,
  phoneNumber: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  acceptsTermsOfUseAndPrivacyPolicy: boolean,
  acceptsPromotionalEmails: boolean,
}

const props = defineProps({
  data: {
    type: Object as PropType<AccountFormModel>
  }
})

const state = reactive(props.data ?? {
  firstName: "",
  lastName: "",
  birthdate: "",
  cpf: "",
  phoneNumber: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  acceptsTermsOfUseAndPrivacyPolicy: false,
  acceptsPromotionalEmails: false,
});

const rules = computed(() => ({
  firstName: {
    required
  },
  lastName: {
    required
  },
  birthdate: {
    required,
    date
  },
  cpf: {
    required,
    validCPF
  },
  phoneNumber: {
    validPhoneNumber,
    required
  },
  email: {
    email,
    required
  },
  password: {
    required,
    minLength: minLength(6)
  },
  passwordConfirmation: {
    required,
    sameAsPassword: sameAs(state.password, 'senha')
  },
  acceptsTermsOfUseAndPrivacyPolicy: {
    checked: sameAs(true, 'termos de uso e política de privacidade')
  }
}))

const standardMaxLengthMask = maxLengthMask(250)

const v$ = useVuelidate(rules, state);

const validate = async () => {
  return await v$.value.$validate();
}

defineExpose({
  validate
})
</script>
<style lang="sass" scoped>
@import 'src/assets/styles/variables'
.account-form
  margin: 0 0 32px 0

  .form-line
    position: relative
    display: flex
    gap: 0 8px
    flex-wrap: wrap

    *
      width: 90px
      flex-shrink: 1
      flex-grow: 1

    .checkbox-input
      a
        text-decoration: underline
</style>
