<template lang="pug">
label.checkbox-input(:class="{ checked: modelValue, error: hasErrors }")

  slot(name="label")
    | {{ label }}

  input(type="checkbox", :name="name", :checked="modelValue", @change="emitChange")

  .checkmark
    svg(width="14", height="10", viewBox="0 0 26 20", fill="none")
      path(
        id="checkmark",
        d="M3 10.625L7.875 16.25L22.875 3.875",
        stroke-width="6",
        stroke-linecap="round",
        stroke-linejoin="round",
        stroke="#EE3D8A")

  span.error {{ errorMessage }}

</template>
<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'

const props = defineProps({
  name: {
    type: String,
  },
  label: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean
  },
  errors: Object as PropType<ErrorObject[]>,
})

const emit = defineEmits(["update:modelValue"])

const hasErrors = computed(() => (props.errors?.length ?? 0) > 0);
const errorMessage = computed(() => hasErrors.value ? props.errors?.[0].$message : "" );

const emitChange = (event: Event) => {
  console.log("emitChange")
  emit("update:modelValue", !props.modelValue)
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'

@keyframes fill-stroke
  0%
    stroke-dashoffset: 27

  100%
    stroke-dashoffset: 0

.checkbox-input
  display: inline-block
  position: relative
  padding-left: 42px
  margin: 12px 0
  cursor: pointer
  font-size: 14px
  font-weight: bold

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

    &:active
      outline: none
      box-shadow: none
      background-color: rgba(0, 0, 0, 0)

    &:checked ~ .checkmark
      border-color: $magenta

      path
        animation-duration: 120ms
        animation-fill-mode: forwards
        animation-timing-function: cubic-bezier(.72,.01,.83,.83)
        animation-name: fill-stroke

  .checkmark
    position: absolute
    display: flex
    top: 50%
    transform: translateY(-50%)
    left: 0
    justify-content: center
    align-items: center
    height: 28px
    width: 28px
    border: 2px solid $magenta
    border-radius: 4px
    background-color: $beige
    box-shadow: inset 0 0 0 2px #fff

    svg > path
      position: absolute
      top: 0px
      left: 0px
      height: 20px
      width: 22px
      z-index: 100
      stroke-dasharray: 27
      stroke-dashoffset: 27
      stroke: $magenta

  span.error
    position: absolute
    top: 32px
    left: 0
    font-size: 12px
    color: $errorColor
    opacity: 0
    transform: translateY(0)
    transition: opacity .16s ease-in-out, transform .16s ease-in-out
</style>